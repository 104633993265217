import moment from "moment";
import { getDateFlightFormat, getDateRange, getDateSearch } from "./helper";

const validateText = (val: string) => {
  return ["", null, undefined].includes(val) ? null : val;
};

export const sanitizeHotelData = (data: any[]) => {
  var temp: MainCardType[] = [];
  var startDate = moment().add(15, "days").calendar();
  var endDate = moment().add(16, "days").calendar();
  data?.map((val) => {
    if (val !== null) {
      let urlBuilder = {
        dest_type: "other",
        dateRange: getDateRange(moment(startDate), moment(endDate)), //"Sun, Aug 28 22 - Wed, Aug 31 22",
        destination_id: String(val?.destination_id),
        latitude: val?.latitude,
        location: `${val?.city_name}, ${val?.country_code}`,
        longitude: val?.longitude,
        paxConfig: '[{"room":1,"adult":2,"children":0,"child":[]}]',
        search_type: "1",
      };
      let queryString = val.search_url
        ? val.search_url
        : new URLSearchParams(urlBuilder).toString();
      return temp.push({
        type: "hotel",
        image: [val?.thumbnail || "undefined"],
        themes: val?.themes,
        title: `${val?.city_name}, ${val?.country_code}`,
        subtitle: val?.hotel_name,
        b2b: val.price.per_night ?? String(val?.price_per_night),
        b2c: "0",
        currency: val?.currency,
        priceConditions: "per night",
        score: val?.customer_rating,
        scoreLabel: "",
        stars: Number(val?.star_rating),
        subtitle2: "",
        price: 0,
        url: { en: queryString },
        creation_time: val.creation_time,
      });
    }
  });

  return temp;
};

export const getCoordinates = (url: string) => {
  let nurl = new URLSearchParams(url);
  let query: CardBodyFlighQuery = Array.from(nurl.keys()).reduce(
    (sum, value) => {
      return Object.assign({ [value]: nurl.get(value) }, sum);
    },
    {}
  );

  if (query.latitude) {
    return { latitude: query.latitude, longitude: query.longitude };
  }

  return {};
};

export const sanitizCarData = (
  data: any[] | RecentSearchesType[] | CarResponseDataCarsType[]
) => {
  var temp: MainCardType[] = [];
  var startDate = moment().add(15, "days").calendar();
  var endDate = moment().add(16, "days").calendar();
  console.log("dataCars", data);
  data?.map((val) => {
    if (val !== null) {
      let urlBuilder = {
        pickLocation: validateText(val.pkuplocinfo) ?? val.pck_city,
        pickUpCode: val.pickupcode,
        dropUpCode: val.dropoffcode,
        dropLocation: val.dpoflocinfo ?? val.drp_city,
        pickupdate: val.pickupdate ?? startDate,
        dropupdate: val.dropupdate ?? endDate,
        picktime: val.picktime ?? "12:00",
        droptime: val.droptime ?? "12:00",
        driverAge: "",
        checkbox: "true",
        pickUpCityName: val.pck_city,
        pickUpCountryName: val.pck_country,
        dropCityName: val.drp_city,
        dropCountryName: val.drp_country,
        latitude: "",
        longitude: "",
      };

      let coordinates = getCoordinates(val.search_url);
      if (coordinates.latitude && coordinates.longitude) {
        urlBuilder = {
          ...urlBuilder,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        };
      }

      let fs = val.fare_summary;

      let perDayPrice =
        fs.perday_basefare ?? Number(fs.totalamt) / Number(fs.days ?? 1);
      let queryString = val.search_url
        ? val.search_url
        : new URLSearchParams(urlBuilder).toString();

      return temp.push({
        type: "car",
        title: val.pck_city
          ? `${val.pck_city},${val.pck_country}`
          : val.dpofloc,
        subtitle: val.carfamily,
        subtitle2: val.cartype,
        priceInfo: perDayPrice,
        priceConditions: "per day",
        brandImage: val.comlogo ?? "/images/cars/brands/europcar.svg",
        url: { en: queryString },
        image: !["", null, undefined].includes(val.car_img)
          ? val.car_img
          : val.loc_image,
        typeImg: ["", null, undefined].includes(val.car_img) ? false : true,
        themes: [],
        price: 0,
        creation_time: val.creation_time,
        days: val.days,
      });
    }
  });

  return temp;
};

const getDate = (date: string) => {
  if (moment(date).format("YYYY-MM-DD") == "Invalid date") {
    var d = new Date(date);
    return [
      d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-");
  }

  return date;
};

const getDateValue = (data: string | string[]) => {
  return typeof data === "object" ? data[0] : data;
};

const checkItHaveReturn = (data: string | string[]) => {
  if (typeof data === "object") {
    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  if (!!data) {
    return true;
  }

  return false;
};

export const sanitizeFlightData = (data: any[], dateFormat: string) => {
  var temp: MainCardType[] = [];

  // const getDestinyName = (value: RecentSearchesFlightType) => {
  //   return value.
  // }

  data?.map((val) => {
    if (val !== null) {
      const dataToFormat = {
        adultscount: val.noofadults,
        childscount: val.noofchilds,
        infantscount: val.noofinfants,
        cabinclass: val.cabinclass,
        cabinClassId: 1,
      };

      const bagsCount = { id: 1, value: "0 Bags", label: "0 Bags" };
      const tripClass = {
        id: 1,
        name: "cabin",
        value: "Economy",
        label: "Economy",
      };
      const dropDownTittleTrip = {
        id: 2,
        value: "Round Trip",
        label: "Round Trip",
      };

      let urlBuilder = {
        flightDesktopValue: val.dep_name ?? val?.bnds[0]?.lgs[0]?.octy,
        flightDesktopValueReturn: val.arvl_name ?? val?.bnds[1]?.lgs[0]?.octy,
        flightDesktopValueCode: val.dep_code ?? val?.src,
        flightDesktopValueReturnCode: val.arvl_code ?? val?.dest,
        from: getDateSearch(moment(getDate(getDateValue(val?.onwarddate)))),
        to: getDateSearch(moment(getDate(getDateValue(val?.returndate)))),
        Traveller: JSON.stringify(dataToFormat),
        tripClass: JSON.stringify(tripClass),
        BagsCount: JSON.stringify(bagsCount),
        dropDownTittleTrip: JSON.stringify(dropDownTittleTrip),
        selectedTripType: val.triptype ?? "",
        selectedClassCabin: val?.cabinclass,
        selectedBags: "1",
        latitude: "",
        longitude: "",
      };

      let coordinates = getCoordinates(val.search_url);
      if (coordinates.latitude && coordinates.longitude) {
        urlBuilder = {
          ...urlBuilder,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        };
      }

      let queryString = val.search_url
        ? val.search_url
        : new URLSearchParams(urlBuilder).toString();

      let departureName = val?.bnds[0]?.lgs[0]?.octy ?? "";
      let returnName = val?.bnds[1]?.lgs[0]?.octy ?? "";
      let cabinclass = val?.bnds[1]?.lgs[0]?.cbn ?? "";
      return temp.push({
        type: "flight",
        title: val.dep_city ?? val.src,
        subtitle: val.arvl_city ?? val.dest,
        subtitle2: String(getDate(getDateValue(val?.onwarddate))),
        dateDeparture: getDateFlightFormat(
          moment(getDate(getDateValue(val?.onwarddate))),
          dateFormat || "YYYY-MM-DD"
        ),
        dateReturn: checkItHaveReturn(val.returndate)
          ? getDateFlightFormat(
              moment(getDate(getDateValue(val?.returndate))),
              dateFormat || "YYYY-MM-DD"
            )
          : undefined,
        persons: String(
          Number(val.noofadults) +
            Number(val.noofchilds) +
            Number(val.noofinfants)
        ),
        bags: "1",
        priceConditions: "",
        brandImage: "1",
        image: !["", null, undefined].includes(val.flight_image)
          ? val.flight_image
          : val.bnds[0].lgs[0].arnimg,
        themes: [],
        price: val.fare_summary.totalpayable,
        url: { en: queryString },
        creation_time: val.creation_time,
        departureName: departureName,
        returnName: returnName,
        cabinclass: cabinclass,
      });
    }
  });

  return temp;
};
